import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Ronde van Drenthe",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-11 22:06:33",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Netherlands: {
        teamId: "Netherlands",
        teamUciCode: "",
        teamName: "Netherlands",
        teamNationCode: "NED",
      },
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "NXG",
        teamName: "NXTG by Experza",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      24514: {
        teamId: "24514",
        teamUciCode: "BDU",
        teamName: "Bizkaia-Durango",
        teamNationCode: "ESP",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28364: {
        teamId: "28364",
        teamUciCode: "ASC",
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        teamNationCode: "LUX",
      },
      28535: {
        teamId: "28535",
        teamUciCode: "GKT",
        teamName: "GT Krush Tunap Pro Cycling",
        teamNationCode: "NED",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
    },
    riders: {
      89708: {
        id: 89708,
        startno: 94,
        firstName: "Lauretta",
        lastName: "Hanson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-10-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      90870: {
        id: 90870,
        startno: 152,
        firstName: "Henrietta",
        lastName: "Colborne",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-04-20",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      90014: {
        id: 90014,
        startno: 31,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      100016: {
        id: 100016,
        startno: 45,
        firstName: "Quinty",
        lastName: "Ton",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-04",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 4,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      96710: {
        id: 96710,
        startno: 126,
        firstName: "Friederike",
        lastName: "Stern",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-02-15",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      90025: {
        id: 90025,
        startno: 75,
        firstName: "Karlijn",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-10-28",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 41,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89514: {
        id: 89514,
        startno: 54,
        firstName: "Lourdes",
        lastName: "Oyarbide",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-04-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89471: {
        id: 89471,
        startno: 64,
        firstName: "Arianna",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-06",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      104996: {
        id: 104996,
        startno: 154,
        firstName: "Anneke",
        lastName: "Dijkstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-05-21",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      91166: {
        id: 91166,
        startno: 121,
        firstName: "Michelle",
        lastName: "Andres",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-05-26",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      97841: {
        id: 97841,
        startno: 22,
        firstName: "Clara",
        lastName: "Copponi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-01-12",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      90989: {
        id: 90989,
        startno: 144,
        firstName: "Martina",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-05",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 173,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      131572: {
        id: 131572,
        startno: 186,
        firstName: "Margaux",
        lastName: "Vigie",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 35,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      96846: {
        id: 96846,
        startno: 194,
        firstName: "Sylvie",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-07-31",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      96695: {
        id: 96695,
        startno: 12,
        firstName: "Shari",
        lastName: "Bossuyt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-09-05",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89551: {
        id: 89551,
        startno: 15,
        firstName: "Lisa",
        lastName: "Klein",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-07-15",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89959: {
        id: 89959,
        startno: 65,
        firstName: "Nina",
        lastName: "Kessler",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1988-07-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      114768: {
        id: 114768,
        startno: 116,
        firstName: "Anne Dorthe",
        lastName: "Ysland",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2002-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 56,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      90127: {
        id: 90127,
        startno: 146,
        firstName: "Lea Lin",
        lastName: "Teutenberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-07-02",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 96,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      92096: {
        id: 92096,
        startno: 105,
        firstName: "Maria",
        lastName: "Novolodskaya",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1999-07-28",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      91081: {
        id: 91081,
        startno: 133,
        firstName: "Sofia",
        lastName: "Rodriguez Revert",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-10-21",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 101,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      114498: {
        id: 114498,
        startno: 164,
        firstName: "Ilse",
        lastName: "Pluimers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-04-29",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      98831: {
        id: 98831,
        startno: 62,
        firstName: "Teniel",
        lastName: "Campbell",
        nationCode: "TRI",
        nationName: "Trinidad & Tobago",
        birthDate: "1997-09-23",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      96734: {
        id: 96734,
        startno: 26,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      114539: {
        id: 114539,
        startno: 183,
        firstName: "Eleonora Camilla",
        lastName: "Gasparrini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      96698: {
        id: 96698,
        startno: 176,
        firstName: "Lieke",
        lastName: "Nooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-07-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      112278: {
        id: 112278,
        startno: 66,
        firstName: "Ruby",
        lastName: "Roseman-Gannon",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-11-08",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89535: {
        id: 89535,
        startno: 113,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 6,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89422: {
        id: 89422,
        startno: 44,
        firstName: "Jeanne",
        lastName: "Korevaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-09-24",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96723: {
        id: 96723,
        startno: 163,
        firstName: "Britt",
        lastName: "Knaven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-07-29",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      95830: {
        id: 95830,
        startno: 125,
        firstName: "Carolin",
        lastName: "Schiff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-01-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      89420: {
        id: 89420,
        startno: 74,
        firstName: "Riejanne",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-01",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      112048: {
        id: 112048,
        startno: 123,
        firstName: "Georgia",
        lastName: "Danford",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1999-04-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      89597: {
        id: 89597,
        startno: 53,
        firstName: "Sheyla",
        lastName: "Gutierrez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-01-01",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89541: {
        id: 89541,
        startno: 52,
        firstName: "Barbara",
        lastName: "Guarischi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-02",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      127994: {
        id: 127994,
        startno: 153,
        firstName: "Femke",
        lastName: "De Vries",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-16",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 85,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      148614: {
        id: 148614,
        startno: 111,
        firstName: "Anniina",
        lastName: "Ahtosalo",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "2003-08-27",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89984: {
        id: 89984,
        startno: 143,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90016: {
        id: 90016,
        startno: 172,
        firstName: "Demi",
        lastName: "De Jong",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-02-11",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89736: {
        id: 89736,
        startno: 92,
        firstName: "Audrey",
        lastName: "Cordon-Ragot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-22",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      96873: {
        id: 96873,
        startno: 166,
        firstName: "Gaia",
        lastName: "Masetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-10-26",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      154489: {
        id: 154489,
        startno: 193,
        firstName: "Mijntje",
        lastName: "Geurts",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-10-25",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      91068: {
        id: 91068,
        startno: 145,
        firstName: "Kathrin",
        lastName: "Schweinberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1996-10-29",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      110027: {
        id: 110027,
        startno: 5,
        firstName: "Franziska",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-07-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      93404: {
        id: 93404,
        startno: 46,
        firstName: "Amber",
        lastName: "Van Der Hulst",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-09-21",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96711: {
        id: 96711,
        startno: 115,
        firstName: "Amalie",
        lastName: "Lutro",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2000-03-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      106425: {
        id: 106425,
        startno: 135,
        firstName: "Catalina",
        lastName: "Soto Campos",
        nationCode: "CHI",
        nationName: "Chile",
        birthDate: "2001-04-08",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      105104: {
        id: 105104,
        startno: 142,
        firstName: "Franziska",
        lastName: "Brau\u00c3\u009fe",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-09-20",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96693: {
        id: 96693,
        startno: 86,
        firstName: "Lonneke",
        lastName: "Uneken",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-03-02",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      92981: {
        id: 92981,
        startno: 124,
        firstName: "Kerry",
        lastName: "Jonker",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-05-21",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      103215: {
        id: 103215,
        startno: 132,
        firstName: "Eukene",
        lastName: "Larrarte Arteaga",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-09-13",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 91,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      93399: {
        id: 93399,
        startno: 162,
        firstName: "Mylene",
        lastName: "De Zoete",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-01-03",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      96746: {
        id: 96746,
        startno: 161,
        firstName: "Julia",
        lastName: "Borgstr\u00c3\u00b6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      90105: {
        id: 90105,
        startno: 24,
        firstName: "Ma\u00c3\u00ablle",
        lastName: "Grosset\u00c3\u00aate",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-04-10",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      90889: {
        id: 90889,
        startno: 182,
        firstName: "Chiara",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-22",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      95821: {
        id: 95821,
        startno: 175,
        firstName: "Femke",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-17",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89426: {
        id: 89426,
        startno: 61,
        firstName: "Georgia",
        lastName: "Baker",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-21",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89637: {
        id: 89637,
        startno: 11,
        firstName: "Alice",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-07-17",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90120: {
        id: 90120,
        startno: 1,
        firstName: "Lorena",
        lastName: "Wiebes",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-03-17",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      96709: {
        id: 96709,
        startno: 196,
        firstName: "Maike",
        lastName: "van der Duin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-12",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89883: {
        id: 89883,
        startno: 83,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89447: {
        id: 89447,
        startno: 72,
        firstName: "Romy",
        lastName: "Kasper",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-05-05",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89421: {
        id: 89421,
        startno: 73,
        firstName: "Anouska",
        lastName: "Koster",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-08-20",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      91960: {
        id: 91960,
        startno: 14,
        firstName: "Ella",
        lastName: "Harris",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-07-18",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89743: {
        id: 89743,
        startno: 114,
        firstName: "Julie",
        lastName: "Leth",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-07-13",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      103237: {
        id: 103237,
        startno: 34,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      94104: {
        id: 94104,
        startno: 151,
        firstName: "Sanne",
        lastName: "Bouwmeester",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-01-26",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 13,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90868: {
        id: 90868,
        startno: 141,
        firstName: "Sandra",
        lastName: "Alonso Dominguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-08-19",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90888: {
        id: 90888,
        startno: 106,
        firstName: "Laura",
        lastName: "Tomasi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-07-01",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96726: {
        id: 96726,
        startno: 171,
        firstName: "Mischa",
        lastName: "Bredewold",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      132707: {
        id: 132707,
        startno: 63,
        firstName: "Kristen",
        lastName: "Faulkner",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-12-18",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89923: {
        id: 89923,
        startno: 82,
        firstName: "Roxane",
        lastName: "Fournier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-11-07",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89497: {
        id: 89497,
        startno: 185,
        firstName: "Ilaria",
        lastName: "Sanguineti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-15",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89964: {
        id: 89964,
        startno: 192,
        firstName: "Thalita",
        lastName: "De Jong",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-11-06",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89446: {
        id: 89446,
        startno: 95,
        firstName: "Chloe",
        lastName: "Hosking",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-10-01",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      96841: {
        id: 96841,
        startno: 93,
        firstName: "Elynor",
        lastName: "Backstedt",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-12-06",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      91011: {
        id: 91011,
        startno: 33,
        firstName: "Evy",
        lastName: "Kuijpers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-02-15",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89951: {
        id: 89951,
        startno: 112,
        firstName: "Susanne",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-23",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89488: {
        id: 89488,
        startno: 43,
        firstName: "Alison",
        lastName: "Jackson",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-12-14",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      176303: {
        id: 176303,
        startno: 134,
        firstName: "Aileen",
        lastName: "Schweikart",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-03-27",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      89413: {
        id: 89413,
        startno: 76,
        firstName: "Jip",
        lastName: "van den Bos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-04-12",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      125405: {
        id: 125405,
        startno: 131,
        firstName: "Daniela",
        lastName: "Campos",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "2002-03-31",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      89871: {
        id: 89871,
        startno: 55,
        firstName: "Gloria",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-03-06",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89537: {
        id: 89537,
        startno: 81,
        firstName: "Elena",
        lastName: "Cecchini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      93080: {
        id: 93080,
        startno: 155,
        firstName: "Melanie",
        lastName: "Klement",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-05-03",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      98982: {
        id: 98982,
        startno: 122,
        firstName: "Fabienne",
        lastName: "Buri",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-03-17",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      89921: {
        id: 89921,
        startno: 23,
        firstName: "Eug\u00c3\u00a9nie",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-05-03",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      97831: {
        id: 97831,
        startno: 181,
        firstName: "Anastasia",
        lastName: "Carbonari",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 174,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89432: {
        id: 89432,
        startno: 16,
        firstName: "Sarah",
        lastName: "Roy",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-02-27",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      96817: {
        id: 96817,
        startno: 2,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89410: {
        id: 89410,
        startno: 84,
        firstName: "Christine",
        lastName: "Majerus",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-02-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      90112: {
        id: 90112,
        startno: 195,
        firstName: "Marjolein",
        lastName: "Van 't Geloof",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-27",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 42,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89542: {
        id: 89542,
        startno: 32,
        firstName: "Mieke",
        lastName: "Kr\u00c3\u00b6ger",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-07-18",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      97542: {
        id: 97542,
        startno: 71,
        firstName: "Teuntje",
        lastName: "Beekhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-18",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 21,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      90886: {
        id: 90886,
        startno: 156,
        firstName: "Melissa",
        lastName: "Van Neck",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-10-13",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      96818: {
        id: 96818,
        startno: 25,
        firstName: "Vittoria",
        lastName: "Guazzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-12-26",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89522: {
        id: 89522,
        startno: 103,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 104,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89872: {
        id: 89872,
        startno: 136,
        firstName: "Alba",
        lastName: "Teruel",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-08-17",
        teamId: 24514,
        teamName: "Bizkaia-Durango",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 102,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      114499: {
        id: 114499,
        startno: 165,
        firstName: "Maud",
        lastName: "Rijnbeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-12-03",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      114495: {
        id: 114495,
        startno: 3,
        firstName: "Megan",
        lastName: "Jastrab",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-01-29",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89917: {
        id: 89917,
        startno: 51,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89719: {
        id: 89719,
        startno: 184,
        firstName: "Silvia",
        lastName: "Persico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-07-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      94463: {
        id: 94463,
        startno: 191,
        firstName: "Loes",
        lastName: "Adegeest",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-07",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Ronde van Drenthe"
  const raceID = 9045

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
